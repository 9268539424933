
// DK2A - 2017/05/02 - 16h40
// ----------------------------------



// RESET GERAL
// ----------------------------------

* { margin: 0; padding: 0; list-style: none; vertical-align: baseline;}




// 0.1 Cores Gerais - Backgrounds
// ---------

.branco {background-color: $white;}
.preto {background-color: $black;}
.grey1 {background-color: $dark-gray;}
.grey2 {background-color: $medium-gray;}
.grey2 {background-color: $light-gray;}
.red {background-color: $red;}
.lime {background-color: $lime;}
.colorBrand1 {background-color: $cli-color-01;}
.colorBrand2 {background-color: $cli-color-02;}
.colorBrand3 {background-color: $cli-color-03;}
.colorBrand4 {background-color: $cli-color-04;}

// ALPHAS
// ------

.pretoAlpha {background-color: $pretoAlpha;}
.brancoAlpha {background-color: $brancoAlpha;}
.cli-color-02Alpha {background-color: $cli-color-02Alpha;}

// 0.2 Cores Gerais - Textos
// ---------

.brancoT {color: $white;}
.pretoT {color: $black;}
.redT {color: $red;}
.colorBrand1T {color: $cli-color-01;}
.colorBrand2T {color: $cli-color-02;}
.colorBrand3T {color: $cli-color-03;}
.colorBrand4T {color: $cli-color-04;}


// 0.3 Fontes Externas Adicionadas
// ---------

@font-face
  { font-family: 'Gunplay-Regular';
    src: url('../fonts/Gunplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gunplay-Regular.woff') format('woff'),
    url('../fonts/Gunplay-Regular.ttf') format('truetype'),
    url('../fonts/Gunplay-Regular.svg#Gunplay-Regular') format('svg');
    font-weight: normal; font-style: normal;
}

@font-face {
  font-family: 'OctinStencilRg-Regular';
  src: url('../fonts/OctinStencilRg-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OctinStencilRg-Regular.woff') format('woff'),
  url('../fonts/OctinStencilRg-Regular.ttf') format('truetype'),
  url('../fonts/OctinStencilRg-Regular.svg#OctinStencilRg-Regular') format('svg');
  font-weight: normal; font-style: normal;
}


// 0.4 Familia Tipografica
// ---------

.fontFamilia-one {font-family: 'Gunplay-Regular';}
.fontFamilia-One {font-family: 'Gunplay-Regular'; text-transform: uppercase;}
.fontFamilia-two {font-family: 'OctinStencilRg-Regular';}
.fontFamilia-Two {font-family: 'OctinStencilRg-Regular'; text-transform: uppercase;}
.fontFamilia-main {font-family: 'Open Sans';}
.fontFamilia-Main {font-family: 'Open Sans'; text-transform: uppercase;}



// 0.5 Tamanho Corpo Tipografico
// ---------

// Average ALL Screens
.fs54s40 {font-size: rem-calc(40); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(54);}}
.fs54s34 {font-size: rem-calc(34); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(54);}}
.fs48s34 {font-size: rem-calc(34); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(48);}}
.fs40s30 {font-size: rem-calc(30); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(40);}}
.fs31s26 {font-size: rem-calc(26); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(31);}}
.fs25s22 {font-size: rem-calc(22); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(25);}}
.fs20s20 {font-size: rem-calc(20); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(20);}}
.fs16s18 {font-size: rem-calc(18); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(16);}}
.fs14s16 {font-size: rem-calc(16); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(14);}}
.fs12s13 {font-size: rem-calc(13); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(12);}}
.fs10s11 {font-size: rem-calc(11); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(10);}}


// Small-Screen: BigFonts  -  BigScreen: SmallFonts
.fs34s54 {font-size: rem-calc(54); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(34);}}
.fs34s48 {font-size: rem-calc(48); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(34);}}
.fs30s40 {font-size: rem-calc(40); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(30);}}
.fs31s26 {font-size: rem-calc(31); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(26);}}
.fs25s22 {font-size: rem-calc(25); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(22);}}
.fs18s16 {font-size: rem-calc(18); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(16);}}
.fs16s14 {font-size: rem-calc(16); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(14);}}
.fs13s12 {font-size: rem-calc(13); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(12);}}


// Equals
.fs54 {font-size: rem-calc(54); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(54);}}
.fs48 {font-size: rem-calc(48); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(48);}}
.fs40 {font-size: rem-calc(40); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(40);}}
.fs31 {font-size: rem-calc(31); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(31);}}
.fs25 {font-size: rem-calc(25); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(25);}}
.fs20 {font-size: rem-calc(20); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(20);}}
.fs18 {font-size: rem-calc(18); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(18);}}
.fs16 {font-size: rem-calc(16); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(16);}}
.fs14 {font-size: rem-calc(14); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(14);}}
.fs12 {font-size: rem-calc(12); margin: 0px; padding: 0px; @include breakpoint(medium) {font-size: rem-calc(12);}}




// 1. Formato SLIDES para CAPAs
// ---------

body {
  background-image: url(../img/large/dk2a-flag.jpg);
  background-attachment: fixed;
  background-position: top left;
  overflow: visible;
  overflow-x: hidden;
}

.capa-full {
  height: 96vh;
  width: 100vw;
}


.capa {
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  padding: rem-calc(20 20 10 20);
  margin: rem-calc(10 10);
  z-index: -9999;

  @include breakpoint(medium) {
    height: 92vh;;
    width: 80vw;
    background-color: $pretoAlpha;
    padding: rem-calc(30 0 10 0);
  }

  @include breakpoint(large) {
    height: 92vh;
    width: 80vw;
    background-color: $pretoAlpha;
    padding: rem-calc(20 0 20 0);
  }

  @include breakpoint(xlarge) {
    height: 80vh;
    width: 70vw;
    background-color: $pretoAlpha;
    padding: rem-calc(20 0 20 0);
  }

  @include breakpoint(xxlarge) {
    height: 80vh;
    width: 70vw;
    background-color: $pretoAlpha;
    padding: rem-calc(10 0 10 0);
    }
}

// 5. DK2A - Video Background
// ---------



// 4. DK2A - LOGO SVG - Responsivo
// ---------

.marca-full1 {
  height: rem-calc(90);
  width: auto;
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(10);
  margin-left: rem-calc(0);
  margin-right: rem-calc(0);
  padding-top: rem-calc(0);
  padding-bottom: rem-calc(10);
  padding-left: rem-calc(0);
  padding-right: rem-calc(0);

  @include breakpoint(medium) {
    height: rem-calc(180);
    width: auto;
    margin-top: rem-calc(0);
    margin-bottom: rem-calc(0);
  }
  @include breakpoint(large) {
    height: rem-calc(200);
    width: auto;
    margin-top: rem-calc(50);
    margin-right: rem-calc(0);
    margin-bottom: rem-calc(80);
    margin-left: rem-calc(0);
    padding: rem-calc(0);
  }
  @include breakpoint(xlarge) {
    height: rem-calc(220);
    width: auto;
    margin-top: rem-calc(80);
    margin-right: rem-calc(0);
    margin-bottom: rem-calc(30);
    margin-left: rem-calc(0);
    padding: rem-calc(0);
  }
}



// EM TESTES
// ---------

.back {
  background-color: red;
}

.back2 {
  background-color: gray;
}

.all-spaces {
  margin-left: rem-calc(100);
  margin-right: rem-calc(100);
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
}

.space {
  padding-bottom: rem-calc(40);

  @include breakpoint(medium) {
    padding-top: rem-calc(4);
    padding-bottom: rem-calc(4);
  }

  @include breakpoint(large) {
    padding-top: rem-calc(0);
    padding-bottom: rem-calc(0);
  }

  @include breakpoint(xlarge) {
    padding-top: rem-calc(0);
    padding-bottom: rem-calc(0);
  }

  @include breakpoint(xxlarge) {
    padding-top: rem-calc(0);
    padding-bottom: rem-calc(0);
    }
}

.top-space {
  padding-top: rem-calc(20);
  padding-left: rem-calc(0);
}

.top-spaces {
  margin-top: rem-calc(40);
  margin-bottom: rem-calc(0);
  padding-bottom: rem-calc(0);
  padding-left: rem-calc(10);
  padding-right: rem-calc(10);
}

.bt-spaces {
  margin-bottom: rem-calc(10);
}

.fix-me {
  clear: both;
}

.topBottom-spaces {
  margin-top: rem-calc(40);
  margin-bottom: rem-calc(20);
}

.no-spaces {
  padding: rem-calc(0);
  margin: rem-calc(0);
}

.around {border-radius: rem-calc(6);}
.around-plus {border-radius: rem-calc(14);}
.around-uber {border-radius: rem-calc(24);}


.sombras {box-shadow: rem-calc(0 0 10) rgba(0,0,0, 0.6);}
.sombras-plus {box-shadow: rem-calc(0 0 20) rgba(0,0,0, 0.6);}
.sombras-uber {box-shadow: rem-calc(0 0 20) rgba(0,0,0, 0.6);}
